import { mdiLinkVariant } from '@mdi/js'

export default [
  {
    subheader: 'TOOLS',
    resource: 'menu_sh_tools',
    action: 'view',
  },
  {
    title: 'Payment Links',
    icon: mdiLinkVariant,
    to: 'tools-payment-link',
    resource: 'payment_links',
    action: 'create',
  },
]
