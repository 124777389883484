const tools = [
  {
    path: '/tools/payment-link',
    name: 'tools-payment-link',
    component: () => import('@/views/tools/payment-link/PaymentLink.vue'),
    meta: {
      layout: 'content',
      resource: 'payment_links',
      action: 'create',
    },
  },
]

export default tools
