const developers = [
  {
    path: '/developers/api-doc',
    name: 'developers-api-doc',
    meta: {
      layout: 'content',
      resource: 'api-doc',
      action: 'view',
    },
    beforeEnter(to, from, next) {
      window.open('https://docs-api.key2pay.io/docs/overview', '_blank');
      next(false); 
    },
  },
  {
    path: '/developers/api-credentials',
    name: 'developers-api-credentials',
    component: () => import('@/views/developers/api-credentials/APICredentialsList.vue'),
    meta: {
      layout: 'content',
      resource: 'api-credentials',
      action: 'list',
    },
  },

]

export default developers