import { mdiCodeBraces, mdiLockOpenOutline } from '@mdi/js'

export default [
  {
    subheader: 'DEVELOPERS',
    resource: 'menu_sh_tools',
    action: 'view',
  },
  {
    title: 'API doc',
    icon: mdiCodeBraces,
    to: 'developers-api-doc',
    resource: 'api_doc',
    action: 'view',
  },
  {
    title: 'API Credentials',
    icon: mdiLockOpenOutline,
    to: 'developers-api-credentials',
    resource: 'api_credentials',
    action: 'list',
  },
]
