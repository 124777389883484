import { mdiReceipt, mdiNoteTextOutline, mdiHandCoinOutline } from '@mdi/js'

export default [
  {
    subheader: 'FINANCE',
    resource: 'menu_sh_finance',
    action: 'view',
  },
  {
    title: 'Settlements',
    icon: mdiNoteTextOutline,
    to: 'finance-settlement-list',
    resource: 'settlements',
    action: 'list',
  },
  {
    title: 'Withdrawals',
    icon: mdiHandCoinOutline,
    to: 'finance-withdrawal-list',
    resource: 'withdrawals',
    action: 'list',
  },
]
